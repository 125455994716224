import React from 'react'

import Writing from "./images/writing"

import classes from "./content-writing.module.scss"
import grid from "./grid.module.scss"

const ContentWriting = () => (

    <section id="content-writing" className={[classes.contentWriting, grid.row].join(' ')}>

        <div className={grid.col}>
            <div className={classes.image}>
                <Writing />
            </div>
        </div>

        <div className={grid.col}>
            <h1 className={classes.title}>Content Writing</h1>
            <p className={classes.content}>You know your story. I'll help you find the right words. 
                Writing services include:</p>
                <br/>
                <ul className={classes.serviceList}>
                    <li>Website Copy</li>
                    <li>Blog Posts</li>
                    <li>Email Campaigns</li>
                </ul>
        </div>




    </section>

)

export default ContentWriting