import React from "react"

import { AnchorLink as Link } from "gatsby-plugin-anchor-links";

import classes from "./hero.module.scss"

import Pistachios from "./images/pistachios"
import DownArrow from "./images/down-arrow"

const Hero = () => (
 <div id="hero" className={classes.hero}>
    <Pistachios />
    <h1 className={classes.heading}>Crack open your web potential.</h1>
    <Link to="/#welcome" stripHash>
      <DownArrow />
    </Link>
 </div>
)

export default Hero