import React from 'react'

import Profile from "./images/profile"

import classes from "./welcome.module.scss"
import grid from "./grid.module.scss"

const Welcome = () => (

    <section id="welcome" className={[classes.welcome, grid.row].join(' ')}>

        <div className={grid.col}>
            <div className={classes.image}>
                <Profile />
            </div>
        </div>

        <div className={grid.col}>
            <h1 className={classes.title}>Welcome</h1>
            <p className={classes.content}>I'm Christopher Galano, principal consultant at Pistachio.
                I'm committed to helping you build a meaningful web presence and telling your story right.
            </p>
        </div>


    </section>

)

export default Welcome