import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classes from "./profile.module.css"

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            alt={"Profile Photo"}
            fadeIn={true}
            durationFadeIn={1500}
            imgStyle={styles.wrapper}
            className={classes.wrapper}
            />
}

export default Profile

const styles = {
  wrapper: {
    borderRadius: `50%`
  }
}