import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Welcome from "../components/welcome"
import WebDevelopment from "../components/web-development"
import ContentWriting from "../components/content-writing"
import Contact from "../components/contact"

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Home" />
        <Hero />
        <Welcome />
        <WebDevelopment />
        <ContentWriting />
        <Contact />
    </Layout>
  )

}


export default IndexPage


