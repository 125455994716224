import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classes from "./down-arrow.module.css"

const DownArrow = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "down-arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            alt={"Scroll Down"}
            imgStyle={styles.image}
            className={classes.wrapper}
            />
}

export default DownArrow

const styles = {
  image: {}
}