import React from 'react'

import Laptop from "./images/laptop"

import classes from "./web-development.module.scss"
import grid from "./grid.module.scss"

const WebDevelopment = () => (

    <section id="web-development" className={[classes.webDevelopment, grid.revRow].join(' ')}>

        <div className={grid.col}>
            <h1 className={classes.title}>Web Development</h1>
            <p className={classes.content}>You know your business. 
            I'll help you build your web presence. Web development services include:</p>
            <br/>
            <ul className={classes.serviceList}>
                <li>Websites</li>
                <li>Web Design</li>
                <li>Web Applications</li>
            </ul>
        </div>

        <div className={grid.col}>
            <div className={classes.image}>
                <Laptop />
            </div>
        </div>



    </section>

)

export default WebDevelopment